<template>
    <v-layout>
        <v-list id="list">
            <v-list-item v-if="!isInStandaloneMode()" @click="bookmark()" class="list-item">
                <div class="d-flex justify-start">
                    <div class="icon bookmark">
                        <img src="/dist/images/icons/bookmark.svg" alt="Bookmark" />
                    </div>
                    <div class="content pl-4 d-flex flex-column">
                        <p class="location-title color-black text-uppercase mb-0">Save To Home Screen</p>
                        <p class="copy mb-0 pr-3">{{ bookmarkText }}</p>
                    </div>
                    <div class="arrow-right d-flex justify-center align-center">
                        <img src="/dist/images/icons/arrow-point-to-right.svg" alt="Arrow" />
                    </div>
                </div>
            </v-list-item>
            <v-list-item @click="login()" class="list-item">
                <div class="d-flex justify-start">
                    <div class="icon">
                        <img src="/dist/images/icons/login.svg" alt="Login" />
                    </div>
                    <div class="content pl-4 d-flex flex-column">
                        <p class="location-title color-black text-uppercase mb-0">LOGIN</p>
                        <p class="copy mb-0 pr-3">{{ loginText }}</p>
                    </div>
                    <div class="arrow-right d-flex justify-center align-center">
                        <img src="/dist/images/icons/arrow-point-to-right.svg" alt="Arrow" />
                    </div>
                </div>
            </v-list-item>
        </v-list>
        <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                color="#0EBCCA"
        >
            <div class="d-flex flex-column flex-md-row">
                <div class="d-flex">
                    <span>To save this app select</span>
                    <span class="menu"><img src="/dist/images/icons/menu.svg" alt="Menu" /></span>
                </div>
                <span class="d-block d-md-inline">then 'Add to Home Screen'</span>
            </div>
            <v-btn
                    color="white"
                    text
                    @click="snackbar = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
        <v-snackbar
                v-model="safariSnackbar"
                :timeout="timeout"
                color="#0EBCCA"
        >
            <div class="d-flex flex-column flex-md-row">
                <div class="d-flex">
                    <span>To save on Safari select</span>
                    <span class="share"><img src="/dist/images/icons/share.svg" alt="Share" /></span>
                </div>
                <span class="d-block d-md-inline">then 'Add to Home Screen'</span>
            </div>
            <v-btn
                    color="white"
                    text
                    @click="safariSnackbar = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
        <v-snackbar
                v-model="iosSnackbar"
                :timeout="timeout"
                color="#0EBCCA"
        >
            <div class="d-flex flex-column flex-md-row">
                <span class="d-block d-md-inline">It looks like you are not using Safari, to download this app first open this site in Safari.</span>
            </div>
            <v-btn
                    color="white"
                    text
                    @click="iosSnackbar = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-layout>
</template>

<script>
    export default {
        data () {
            return {
                loginText: decodeURI(window.GLOBALS.login_text),
                loginPath: decodeURI(window.GLOBALS.login_path),
                bookmarkText: decodeURI(window.GLOBALS.bookmark_text),
                snackbar: false,
                safariSnackbar: false,
                iosSnackbar: false,
                timeout: 10000
            }
        },
        methods: {
            login() {
                window.open(this.loginPath, '_blank');
            },
            bookmark() {
                if (this.isIos()) {
                    this.iosSnackbar = true;
                } else if (this.isSafari()) {
                    this.safariSnackbar = true;
                } else {
                    if (this.hasPrompt()) {
                        window.DEFERRED_PROMPT.prompt();
                        ga('send', 'event', 'PWA', 'Save to home clicked');
                    } else {
                        this.snackbar = true;
                    }
                }
            },
            hasPrompt() {
                return window.DEFERRED_PROMPT !== null;
            },
            isIos() {
                return navigator.userAgent.toLowerCase().match('crios');
            },
            isSafari() {
                return navigator.userAgent.toLowerCase().indexOf("safari") > -1 && navigator.userAgent.toLowerCase().indexOf("chrome") === -1;
            },
            isInStandaloneMode() {
                return ('standalone' in window.navigator) && (window.navigator.standalone);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables";
    @import "../styles/functions";
    @import "../styles/mixins";

    .v-list {
        padding: 0;
        width: 100%;
    }
    .menu,
    .share {
        width: 20px;
        margin: 0 5px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .v-snack {
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            bottom: 67px;
        }
    }
    #list {
        .list-item {
            height: 100px;
            background: #FFFFFF;
            padding: 0;
            > div {
                position: relative;
                height: 100px;
                padding: 20px 16px;
                width: 100%;
                background: linear-gradient(0deg, rgba(255,255,255,0.00) 50%, rgba(0,0,0,0.10) 100%);
            }
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 46px;
                max-width: 46px;
                min-width: 46px;
                @media screen and (max-width: 330px) {
                    width: 35px;
                    max-width: 35px;
                    min-width: 35px;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                &.bookmark {
                    img {
                        width: 38px;
                    }
                }

            }
            .content {
                justify-content: center;
                flex-grow: 1;
                .copy {
                    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                        font-size: 12px;
                    }
                }
            }
            .arrow-right {
                margin-right: 15px;
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    margin-right: 10px;
                }
            }
        }
    }
</style>