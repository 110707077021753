import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const module = {
    state,
    mutations,
    actions,
    getters
};

export default module;