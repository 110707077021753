<template>
    <v-layout>
        <v-list v-if="locations.length === 0">
            <v-list-item class="location-item">
                <div class="d-flex">
                    <div class="content d-flex">
                        <p class="location-title color-black text-uppercase mb-0">There are currently no locations in view</p>
                    </div>
                </div>
            </v-list-item>
        </v-list>
        <v-list v-else>
            <v-list-item v-for="(item, i) in locations" :key="i" @click="onDetails(item)" class="location-item">
                <div class="d-flex justify-space-between">
                    <div class="content d-flex flex-column">
                        <p class="location-title color-black text-uppercase mb-0">{{ item.title }}</p>
                        <p class="address mb-0">{{ item.suburb }} NSW {{ item.postcode }}</p>
                    </div>
                    <div class="icons d-flex">
                        <div class="favourite" @click.stop="onFavourite(item)">
                            <img v-if="isFavourite(item)" src="/dist/images/icons/heart_outline_favourite.svg" alt="Favourite" />
                            <img v-else src="/dist/images/icons/heart_outline.svg" alt="Favourite" />
                        </div>
                        <div class="information ml-3" @click="onDetails(item)">
                            <img src="/dist/images/icons/information.svg" alt="Information" />
                        </div>
                    </div>
                </div>
            </v-list-item>
        </v-list>
    </v-layout>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: [
            'locations'
        ],
        data () {
            return {

            }
        },
        computed: {
            ...mapState({
                favourites: state => state.favourites.favourites
            })
        },
        methods: {
            onFavourite (location) {
                // Select favourite for this item
                this.$store.dispatch('favouriteLocationSelect', location);
            },
            onDetails (location) {
                // Open location detail view
                this.$store.dispatch('showLocationDetails', location);
            },
            isFavourite (location) {
                let isFavourite = false;
                this.favourites.map((loc) => {
                    if (loc.id === location.id) {
                        isFavourite = true;
                    }
                });

                return isFavourite;
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";
    @import "../../styles/functions";
    @import "../../styles/mixins";

    .v-list {
        padding: 0;
        width: 100%;
    }
    .location-item {
        height: 75px;
        background: #FFFFFF;
        padding: 0;
        > div {
            position: relative;
            height: 75px;
            padding: 20px 36px 20px 16px;
            width: 100%;
            background: linear-gradient(0deg, rgba(255,255,255,0.00) 50%, rgba(0,0,0,0.10) 100%);
        }
        .icons {
            .favourite,
            .information {
                pointer-events: all;
                cursor: pointer;
            }
        }
    }
</style>