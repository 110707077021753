import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
Vue.use(Vuex);

import favourites from './modules/favourites/index'
import locations from './modules/locations/index'

const vuexPersist = new VuexPersist({
    key: 'courtesy-bus',
    storage: window.localStorage
});

export default new Vuex.Store({
    modules: {
        favourites,
        locations
    },
    plugins: [vuexPersist.plugin]
});