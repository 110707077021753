<template>
    <footer>
        <nav>
            <ul>
                <li v-for="(link, index) in tabs" :key="index" class="tab">
                    <router-link :to="link.to" v-slot="{ href, route, navigate, isActive, isExactActive }">
                        <a @click="navigate" :href="href">
                            <span class="icon">
                                <img :src="isExactActive ? link.icon_active : link.icon" :alt="link.title" />
                            </span>
                            <span :class="[isExactActive && 'active']">{{ link.name }}</span>
                        </a>
                    </router-link>
                </li>
            </ul>
        </nav>
    </footer>
</template>

<script>
    export default {
        data: () => ({
            tabs: [
                {
                    name: 'Search',
                    to: '/',
                    icon: '/dist/images/icons/search.svg',
                    icon_active: '/dist/images/icons/search-active.svg'
                },
                {
                    name: 'Venue List',
                    to: '/venue-list',
                    icon: '/dist/images/icons/list.svg',
                    icon_active: '/dist/images/icons/list-active.svg'
                },
                {
                    name: 'Saved',
                    to: '/saved',
                    icon: '/dist/images/icons/heart.svg',
                    icon_active: '/dist/images/icons/heart-active.svg'
                },
                {
                    name: 'More',
                    to: '/more',
                    icon: '/dist/images/icons/more.svg',
                    icon_active: '/dist/images/icons/more-active.svg'
                }
            ]
        })
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables";
    @import "../styles/functions";
    @import "../styles/mixins";

    footer {
        z-index: 99;
        box-shadow: 0 2px 28px 0 rgba(0,0,0,0.43);
        font-size: 10px;
        color: #4A4A4A;
        letter-spacing: 0;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        padding: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        height: 70px;
        padding-bottom: 15px;

        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            display: flex;
        }
        nav {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        ul {
            height: 62px;
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            width: 100%;
            max-width: 600px;
            @media screen and (max-width: 325px) {
                padding: 10px 15px;
            }
            li {
                padding: 0 5px;
                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    height: 100%;

                    @extend %font-bold;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: $body-color;
                    letter-spacing: 0.7px;
                    text-decoration: none;
                    span.icon {
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    span.active {
                        color: $primary;
                    }
                    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                        font-size: 10px;
                        letter-spacing: 0;
                    }
                }
            }
        }
    }
</style>