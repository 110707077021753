require('./bootstrap');
import 'babel-polyfill'
import Vue from 'vue'
import router from './router'
import Vuex from 'vuex'
Vue.use(Vuex);

/**
 * Components
 */
import App from './App.vue'
import Header from './components/Header'
import Tabbar from './components/Tabbar'
import LocationList from './components/search/LocationList'
import LocationDetail from './components/location/LocationDetail'
import LocationSearch from './components/venuelist/LocationSearch'
import FavouritesList from './components/favourites/FavouritesList'

Vue.component('app', App);
Vue.component('navbar', Header);
Vue.component('tabbar', Tabbar);
Vue.component('location-list', LocationList);
Vue.component('location-detail', LocationDetail);
Vue.component('location-search', LocationSearch);
Vue.component('favourites-list', FavouritesList);

/**
 * PWA Service worker
 */
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('service-worker-cache.js').then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

/**
 * PWA Bookmark
 */
window.DEFERRED_PROMPT = null;
window.addEventListener('beforeinstallprompt', (e) => {
    // Stash the event so it can be triggered later.
    window.DEFERRED_PROMPT = e;
});
window.addEventListener('appinstalled', (evt) => {
    console.log('App installed');
    ga('send', 'event', 'PWA', 'App installed');
});

/**
 * VueX
 */
import store from './store';

/**
 * Vuetify
 */
import vuetify from '@/plugins/vuetify';

new Vue({
    el: '#courtesy-bus',
    router,
    store,
    vuetify
});