const api = (route) => {
    return '/api/v1/' + route
};
export default {
    getLocations (cb) {
        axios.get(api('locations'))
            .then((response) => {
                cb(response.data.data);
            })
    },
}