const mutations = {
    setFavourites (state, favourites) {
        state.favourites = favourites;
    },
    favouriteLocationSelect (state, location) {
        let favourites = state.favourites,
            found = false,
            i = 0;

        favourites.forEach((fav, index) => {
             if (fav.id === location.id) {
                 found = true;
                 i = index;
             }
        });

        if (found) {
            favourites.splice(i, 1);
        } else {
            favourites.push(location);
        }

        state.favourites = favourites;
    }
};

export default mutations;