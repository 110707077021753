<template>
    <v-layout>
        <v-navigation-drawer
                id="saved-venues"
                :value="true"
                hide-overlay
                absolute
                permanent
                width="40vw"
                style="background: #FEFEFE"
                disable-resize-watcher
                mobile-break-point="960"
                stateless
        >
            <favourites-list @remove="favRemoved"></favourites-list>
        </v-navigation-drawer>
        <v-navigation-drawer
                id="detail-view"
                :value="showLocationDetail"
                hide-overlay
                absolute
                width="40vw"
                style="background: #FEFEFE"
                disable-resize-watcher
                mobile-break-point="960"
                stateless
        >
            <location-detail></location-detail>
        </v-navigation-drawer>
        <div id="map"></div>
    </v-layout>
</template>

<script>
    import { mapState } from 'vuex';
    import gmapsInit from '../../utils/gmaps';
    import MarkerClusterer from "@/utils/clusterer";
    import mapStyles from '@/utils/map_styles';

    export default {
        data () {
            return {
                map: null,
                markers: [],
                polygons: [],
                cluster: null
            }
        },
        methods: {
            initMap() {
                this.map = new google.maps.Map(document.getElementById('map'), {
                    center: {lat: -33.0311, lng: 151.6603},
                    zoom: 12,
                    disableDefaultUI: true,
                    styles: mapStyles
                });

                this.setMarkers();

                // Listeners
                google.maps.event.addListener(this.map, 'bounds_changed', () => {
                    if (this.map.getZoom() > 12) {
                        this.showPolygons();
                    } else {
                        this.hidePolygons();
                    }
                });
            },
            setMarkers() {
                let saved = this;
                this.markers = this.favourites.map((location) => {
                    if (location.hasOwnProperty('latitude') && location.latitude !== null && location.hasOwnProperty('longitude') && location.longitude !== null) {
                        let marker = new google.maps.Marker({
                            position: new google.maps.LatLng(location.latitude, location.longitude),
                            icon: '/dist/images/icons/map_marker.png',
                            map: saved.map
                        });

                        marker['locationId'] = location.id;

                        marker.addListener('click', function() {
                            saved.$store.dispatch('showLocationDetails', location);
                        });

                        return marker;
                    }
                });

                this.cluster = new MarkerClusterer(this.map, this.markers, {
                    imagePath: '/dist/images/icons/clusters/m',
                    styles: {
                        width: 15,
                        textColor: 'transparent',
                        textSize: 0
                    },
                    gridSize: 40
                });
            },
            removeMarkers() {
                this.markers.forEach((marker) => {
                    marker.setMap(null);
                });

                this.markers = [];
            },
            showPolygons () {
                if (this.polygons.length === 0) {
                    this.polygons = this.favourites.map((location) => {
                        let paths = JSON.parse(location.bus_radius);
                        if (paths) {
                            // Because we have to use a Polyline to allow dots,
                            // we need to re-add the first coord to the end of the path to create the final link in the loop
                            paths.push(paths[0]);

                            // Create the symbol for the dotted line
                            let lineSymbol = {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillOpacity: 1,
                                scale: 1.5,
                                fillColor: '#192B41'
                            };

                            let polylineDotted = new google.maps.Polyline({
                                strokeColor: '#192B41',
                                strokeOpacity: 0,
                                icons: [{
                                    icon: lineSymbol,
                                    offset: '0',
                                    repeat: '10px'
                                }],
                                path: paths,
                                map: this.map
                            });

                            return polylineDotted;
                        }
                    });
                }
            },
            hidePolygons () {
                this.polygons.forEach((polygon) => {
                    polygon.setMap(null);
                });

                this.polygons = [];
            },
            favRemoved(location) {
                this.markers.forEach((marker) => {
                    if (marker.locationId === location.id) {
                        marker.setMap(null);
                    }
                });
            }
        },
        computed: {
            ...mapState({
                favourites: state => state.favourites.favourites,
                showLocationDetail: state => state.locations.showLocationDetail
            })
        },
        async mounted () {
            try {
                const google = await gmapsInit();
                this.initMap();
            } catch (error) {
                console.error(error);
            }

            this.$store.dispatch('getLocations');
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";
    @import "../../styles/functions";
    @import "../../styles/mixins";

    #saved-venues,
    #detail-view {
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            width: 100vw !important;
        }
    }
    #map {
        width: 60vw;
        margin-left: auto;
        height: calc(100vh - 90px);
        transition: width 0.3s;
    }
</style>