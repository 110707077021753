<template>
    <header>
        <div class="logo">
            <router-link to="/">
                <img src="/dist/images/logo.svg" alt="Courtesy Bus" />
            </router-link>
        </div>
        <nav>
            <ul>
                <li v-for="(link, index) in links" :key="index">
                    <router-link :to="link.to">
                        {{ link.name }}
                    </router-link>
                </li>
            </ul>
        </nav>
        <div class="la-logo">
            <img src="/dist/images/liquoraccord.png" alt="Liquor Accord" />
        </div>
    </header>
</template>

<style lang="scss" scoped>
    @import "../styles/variables";
    @import "../styles/functions";
    @import "../styles/mixins";

    header {
        z-index: 99;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0 2px 9px 0 rgba(0,0,0,0.06);
        height: 90px;
        padding: 15px 20px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            height: 66px;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            padding: 5px 10px;
        }
        .logo,
        .la-logo {
            display: flex;
            align-items: center;
            img {
                width: 100%;
                height: auto;
            }
        }
        .logo {
            width: 100%;
            max-width: 200px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                max-width: 175px;
            }
        }
        .la-logo {
            width: 100%;
            max-width: 92px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                max-width: 75px;
            }
        }
        ul {
            list-style: none;
            display: flex;
            padding: 40px;
            @media screen and (max-width: map-get($css-breakpoints, "md")) {
                display: none;
            }
            li {
                a {
                    @extend %font-bold;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: $body-color;
                    letter-spacing: 0.7px;
                    text-decoration: none;
                    padding: 15px 22px;
                    &.router-link-exact-active {
                        color: $primary;
                    }
                }
            }
        }
    }
</style>

<script>
    export default {
        data: () => ({
            links: [
                {
                    name: 'Search',
                    to: '/'
                },
                {
                    name: 'Venue List',
                    to: '/venue-list'
                },
                {
                    name: 'Saved',
                    to: '/saved'
                },
                {
                    name: 'More',
                    to: '/more'
                }
            ]
        })
    }
</script>