<template>
    <div class="location-detail">
        <div class="map-container d-block d-md-none">
            <div id="location-map"></div>
            <div class="back" @click="close">
                <img src="/dist/images/icons/back.svg" alt="Back" />
            </div>
            <div class="favourite" @click.stop="onFavourite()">
                <img v-if="isFavourite()" src="/dist/images/icons/heart_outline_favourite.svg" alt="Favourite" />
                <img v-else src="/dist/images/icons/heart_outline.svg" alt="Favourite" />
            </div>
        </div>
        <div class="pa-3 pa-sm-5">
            <div class="d-flex justify-space-between mb-5">
                <div class="d-flex flex-column">
                    <p class="location-title lrg-title color-primary mb-0">{{ location.title }}</p>
                    <p class="address mb-0">{{ location.suburb }} NSW {{ location.postcode }}</p>
                </div>
                <div class="close d-none d-md-block" @click="close">
                    <img src="/dist/images/icons/close.svg" alt="Close" />
                </div>
            </div>
            <div class="d-flex flex-column information">
                <div class="d-flex phone justify-start align-center mb-3">
                    <div class="icon">
                        <img src="/dist/images/icons/phone.svg" alt="Phone" />
                    </div>
                    <div class="details">
                        <a :href="'tel:' + location.phone" title="Phone">{{ location.phone }}</a>
                    </div>
                </div>
                <div class="d-flex seats justify-start align-center mb-3">
                    <div class="icon">
                        <img src="/dist/images/icons/seat.svg" alt="Bus Seats" />
                    </div>
                    <div class="details">
                        <p class="copy mb-0">{{ location.bus_seats }}</p>
                    </div>
                </div>
                <div class="d-flex disability justify-start align-center mb-3">
                    <div class="icon">
                        <img src="/dist/images/icons/wheelchair.svg" alt="Disabled Access" />
                    </div>
                    <div class="details">
                        <p class="copy mb-0">{{ location.bus_disability === 'noaccess' ? "No disability access" : "Has disabled access" }}</p>
                    </div>
                </div>
                <div v-if="location.bus_disability === 'noaccess'" class="d-flex disability justify-start align-center mb-3">
                    <div class="icon"></div>
                    <div class="details">
                        <a class="taxi font-black" href="tel:132227">ORDER TAXI</a>
                    </div>
                </div>
                <div v-if="notEmpty(location.bus_limitiations)" class="d-flex limitations justify-start align-start mb-5 mt-3 mt-sm-5">
                    <div class="icon">
                        <img src="/dist/images/icons/warning.svg" alt="Bus Limitations" />
                    </div>
                    <div class="details">
                        <p class="copy mb-0">{{ location.bus_limitations }}</p>
                    </div>
                </div>
                <div v-if="notEmpty(location.website)" class="d-flex website justify-start align-center mb-7">
                    <div class="icon">
                        <img src="/dist/images/icons/website.svg" alt="Website" />
                    </div>
                    <div class="details">
                        <a class="font-black" :href="location.website" title="Website" target="_blank" rel="nofollow">{{ location.website }}</a>
                    </div>
                </div>
            </div>
            <hr />
            <div class="d-flex flex-column flex-lg-row py-5 py-sm-7">
                <div>
                    <p class="font-black text-uppercase mb-1 font-size-13">Pickup</p>
                    <div class="copy pr-0 pr-sm-3 mb-5 mb-lg-0">{{ location.desc_pickup }}</div>
                </div>
                <div>
                    <p class="font-black text-uppercase mb-1 font-size-13">Dropoff</p>
                    <div class="copy pr-0 pr-sm-3">{{ location.desc_dropoff }}</div>
                </div>
            </div>
            <hr />
            <div v-if="notEmpty(location.desc_additional)" class="py-5 py-sm-7 additional-info">
                <p class="font-black text-uppercase mb-2 font-size-13">Additional Information</p>
                <div class="copy pr-0 pr-sm-3">{{ location.desc_additional }}</div>
            </div>
            <div v-else class="additional-info"></div>
        </div>
        <v-btn class="d-block d-md-none sticky-phone" block color="#08A0B5" dark>
            <a :href="'tel:' + location.phone" title="Phone">
                <img src="/dist/images/icons/phone_white.svg" alt="Phone" />
                <span class="font-black color-white">CALL NOW</span>
            </a>
        </v-btn>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import mapStyles from '@/utils/map_styles';
    import gmapsInit from '../../utils/gmaps';

    export default {
        data () {
            return {
                map: null,
            }
        },
        computed: {
            ...mapState({
                location: state => state.locations.selectedLocation,
                favourites: state => state.favourites.favourites
            }),
            ...mapGetters([
                'activeLocations'
            ])
        },
        methods: {
            initMap() {
                let center = {lat: -33.0311, lng: 151.6603};
                if (this.location.hasOwnProperty('latitude')) {
                    center = new google.maps.LatLng(this.location.latitude, this.location.longitude);
                }
                this.map = new google.maps.Map(document.getElementById('location-map'), {
                    center: center,
                    zoom: 12,
                    disableDefaultUI: true,
                    styles: mapStyles
                });

                if (this.location.hasOwnProperty('id')) {
                    let search = this,
                        marker = null,
                        loc = this.location;
                    if (loc.hasOwnProperty('latitude') && loc.latitude !== null && loc.hasOwnProperty('longitude') && loc.longitude !== null) {
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(loc.latitude, loc.longitude),
                            icon: '/dist/images/icons/map_marker.png',
                            map: search.map
                        });

                        marker.addListener('click', function() {
                            search.$store.dispatch('showLocationDetails', loc);
                        });
                    }

                    let paths = JSON.parse(loc.bus_radius);
                    if (paths) {
                        // Because we have to use a Polyline to allow dots,
                        // we need to re-add the first coord to the end of the path to create the final link in the loop
                        paths.push(paths[0]);

                        // Create the symbol for the dotted line
                        let lineSymbol = {
                            path: google.maps.SymbolPath.CIRCLE,
                            fillOpacity: 1,
                            scale: 1.5,
                            fillColor: '#192B41'
                        };

                        let polylineDotted = new google.maps.Polyline({
                            strokeColor: '#192B41',
                            strokeOpacity: 0,
                            icons: [{
                                icon: lineSymbol,
                                offset: '0',
                                repeat: '10px'
                            }],
                            path: paths,
                            map: this.map
                        });

                        return polylineDotted;
                    }
                }
            },
            close () {
                this.$store.dispatch('closeLocationDetails');
                this.$emit('close');
            },
            onFavourite () {
                // Select favourite for this item
                this.$store.dispatch('favouriteLocationSelect', this.location);
            },
            isFavourite () {
                let isFavourite = false;
                this.favourites.map((loc) => {
                    if (loc.id === this.location.id) {
                        isFavourite = true;
                    }
                });

                return isFavourite;
            },
            notEmpty(value) {
                return value !== "" && value !== undefined;
            }
        },
        created: function () {
            let self = this;
            this.$store.watch(state => state.locations.selectedLocation, () => {
                const selectedLocation = this.$store.state.locations.selectedLocation;
                if (selectedLocation !== '' && selectedLocation.hasOwnProperty('id') && window.innerWidth < 960) {
                    if (self.map) {
                        self.map.setZoom(12);
                        self.map.setCenter(new google.maps.LatLng(selectedLocation.latitude, selectedLocation.longitude));
                    }
                }
            })
        },
        watch: {
            location(newValue, oldValue) {
                if (newValue.hasOwnProperty('id')) {
                    this.initMap();
                }
            }
        },
        async mounted () {
            try {
                const google = await gmapsInit();
                this.initMap();
            } catch (error) {
                console.error(error);
            }

            this.$store.dispatch('getLocations');
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";
    @import "../../styles/functions";
    @import "../../styles/mixins";

    .location-detail {
        background: #FEFEFE;
        height: 100%;
        .map-container {
            position: relative;
            #location-map {
                width: 100%;
                height: 400px;
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    height: 200px;
                }
                @media screen and (max-height: 500px) {
                    height: 150px;
                }
            }
            .back,
            .favourite {
                z-index: 10;
                position: absolute;
                top: 10px;
                height: 35px;
                width: 35px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .back {
                left: 10px;
            }
            .favourite {
                right: 10px;
            }
        }
        .close {
            height: 47px;
            padding: 10px;
        }
        .information {
            padding-right: 40px;
            @media screen and (max-width: map-get($css-breakpoints, "md")) {
                padding-right: 0;
            }
            a {
                color: $body-color;
            }
            .icon {
                margin-right: 15px;
                width: 20px;
                min-width: 20px;
                max-width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .taxi {
                position: relative;
                top: -3px;
                display: inline-block;
                height: 26px;
                width: 118px;
                text-decoration: none;
                border: 2px solid $primary;
                border-radius: 11px;
                font-size: 11px;
                color: $primary;
                letter-spacing: 0.73px;
                text-align: center;
                line-height: 23px;
            }
            .website {
                a {
                    text-decoration: none;
                    color: $primary;
                    font-size: 14px;
                    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                        font-size: 12px;
                    }
                }
            }
        }
        hr {
            width: 100%;
            border: 1px solid #DEDEDE;
        }
        .additional-info {
            @media screen and (max-width: map-get($css-breakpoints, "md")) {
                padding-bottom: 65px !important;
            }
        }
        .sticky-phone {
            position: absolute;
            bottom: 0 !important;
            border: none;
            border-radius: 0;
            left: 0;
            right: 0;
            height: 62px !important;
            a {
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }
            span {
                font-size: 17px;
                letter-spacing: 1px;
                text-align: center;
            }
        }
    }
</style>
