import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Search from '@/components/search/Search'
import Venues from '@/components/venuelist/Venues'
import Saved from '@/components/favourites/Saved'
import More from '@/components/More'

// Fallback page
import PageNotFound from '@/components/PageNotFound'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'Search',
            path: '/',
            component: Search
        },
        {
            name: 'Venue List',
            path: '/venue-list',
            component: Venues
        },
        {
            name: 'Saved',
            path: '/saved',
            component: Saved
        },
        {
            name: 'More',
            path: '/more',
            component: More
        },
        {
            path: '**',
            name: 'PageNotFound',
            component: PageNotFound
        }
    ]
});