<template>
    <v-layout id="container-layout">
        <div class="search">
            <div class="icon">
                <img src="/dist/images/icons/search-active.svg" alt="Search" />
            </div>
            <v-text-field
                    v-model="search"
                    label="Search by suburb / postcode"
                    solo
                    flat
                    hide-details
                    color="#0EBCCA"
            ></v-text-field>
        </div>
        <v-list id="results-list">
            <div class="list-group" v-for="(group, l) in groupedLocations" :data-letter="l">
                <v-subheader class="sticky" :data-ref="l">{{ l.toUpperCase() }}</v-subheader>
                <v-list-item-group>
                    <v-list-item v-for="(location, i) in group"
                                 :key="i"
                                 @click="locationSelect(location)"
                                 :data-key="l"
                                 class="location-item">
                        <div class="d-flex justify-space-between">
                            <div class="content d-flex flex-column">
                                <p class="location-title color-black text-uppercase mb-0">{{ location.title }}</p>
                                <p class="address mb-0">{{ location.suburb }} NSW {{ location.postcode }}</p>
                            </div>
                            <div class="icons d-none d-sm-flex">
                                <div class="favourite" @click.stop="onFavourite(location)">
                                    <img v-if="isFavourite(location)" src="/dist/images/icons/heart_outline_favourite.svg" alt="Favourite" />
                                    <img v-else src="/dist/images/icons/heart_outline.svg" alt="Favourite" />
                                </div>
                                <div class="information ml-3" @click="locationInformation(location)">
                                    <img src="/dist/images/icons/information.svg" alt="Information" />
                                </div>
                            </div>
                        </div>
                    </v-list-item>
                </v-list-item-group>
            </div>
        </v-list>
        <v-list id="alphabet-list">
            <v-list-item-group v-model="activeLetter">
                <v-list-item v-for="(item, i) in alphabet" :key="i" :class="{ disabled : letterDisabled(item), active : i === activeLetter }" @click="scrollToLetter(item)">
                    {{ item }}
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-layout>
</template>

<script>
    // Ref https://developers.google.com/web/updates/2017/09/sticky-headers for sticky
    import { mapState, mapGetters } from 'vuex';

    export default {
        data () {
            return {
                alphaletters: "abcdefghijklmnopqrstuvwxyz",
                activeLetter: 0,
                search: ''
            }
        },
        computed: {
            ...mapState({
                favourites: state => state.favourites.favourites
            }),
            ...mapGetters([
                'activeLocations'
            ]),
            alphabet() {
                return this.alphaletters.split('');
            },
            groupedLocations() {
                let grouped = {};

                // Group locations by letter
                this.activeLocations.forEach((location) => {
                    let letter = location.title.charAt(0).toLowerCase();
                    if (this.search !== "") {
                        if (location.suburb.toLowerCase().search(this.search.toLowerCase()) >= 0 || location.postcode.toString().search(this.search.toString()) >= 0) {
                            if (!grouped.hasOwnProperty(letter)) {
                                grouped[letter] = [];
                            }
                            grouped[letter].push(location);
                        }
                    } else {
                        if (!grouped.hasOwnProperty(letter)) {
                            grouped[letter] = [];
                        }
                        grouped[letter].push(location);
                    }
                });

                // Sort groupings
                let sorted = {};
                Object.keys(grouped).sort().forEach(function(key) {
                    sorted[key] = grouped[key].sort((a, b) => {
                        if (a.title < b.title)
                            return -1;
                        if (a.title > b.title)
                            return 1;
                        return 0;
                    })
                });

                return sorted;
            }
        },
        methods: {
            locationSelect (location) {
                // Open location detail view
                this.$store.dispatch('showLocationDetails', location);
            },
            locationInformation (location) {

            },
            onFavourite (location) {
                // Select favourite for this item
                this.$store.dispatch('favouriteLocationSelect', location);
            },
            isFavourite (location) {
                let isFavourite = false;
                this.favourites.map((loc) => {
                    if (loc.id === location.id) {
                        isFavourite = true;
                    }
                });

                return isFavourite;
            },
            letterDisabled(letter) {
                return !this.groupedLocations.hasOwnProperty(letter);
            },
            getFirstLetter() {
                let first = 0;
                this.alphabet.forEach((letter, i) => {
                    if (this.groupedLocations.hasOwnProperty(letter) && first === 0) {
                        first = i;
                    }
                });

                return first;
            },
            scrollToLetter(letter) {
                let top;
                if (window.innerWidth > 960) {
                    top = $(".list-group[data-letter='" + letter + "']").offset().top + $('#venue-search .v-navigation-drawer__content').scrollTop() - $('.search').height() - 100;
                } else {
                    top = $(".list-group[data-letter='" + letter + "']").offset().top + $('#venue-search .v-navigation-drawer__content').scrollTop() - $('.search').height() - 76;
                }
                $('#venue-search .v-navigation-drawer__content').animate({scrollTop: top}, "fast");
            },
            stickyObservers() {
                document.addEventListener('sticky-change', e => {
                    const header = e.detail.target;  // header became sticky or stopped sticking.
                    const sticking = e.detail.stuck; // true when header is sticky.

                    let letter = $(header).data('ref');
                    if (sticking) {
                        this.activeLetter = this.alphabet.indexOf(letter);
                    } else {
                        this.activeLetter = this.alphabet.indexOf(letter) - 1;
                    }
                });

                /**
                 * Notifies when elements w/ the `sticky` class begin to stick or stop sticking.
                 * Note: the elements should be children of `container`.
                 * @param {!Element} container
                 */
                function observeStickyHeaderChanges(container) {
                    observeHeaders(container);
                    //observeFooters(container);
                }

                observeStickyHeaderChanges(document.querySelector('#venue-search'));

                /**
                 * Sets up an intersection observer to notify when elements with the class
                 * `.sticky_sentinel--top` become visible/invisible at the top of the container.
                 * @param {!Element} container
                 */
                function observeHeaders(container) {
                    const observer = new IntersectionObserver((records, observer) => {
                        for (const record of records) {
                            const targetInfo = record.boundingClientRect;
                            const stickyTarget = record.target.parentElement.querySelector('.sticky');
                            const rootBoundsInfo = record.rootBounds;

                            // Started sticking.
                            if (targetInfo.bottom < rootBoundsInfo.top) {
                                fireEvent(true, stickyTarget);
                            }

                            // Stopped sticking.
                            if (targetInfo.bottom >= rootBoundsInfo.top &&
                                targetInfo.bottom < rootBoundsInfo.bottom) {
                                fireEvent(false, stickyTarget);
                            }
                        }
                    }, {threshold: [0], root: container});

                    // Add the top sentinels to each section and attach an observer.
                    const sentinels = addSentinels(container, 'sticky_sentinel--top');
                    sentinels.forEach(el => observer.observe(el));
                }

                /**
                 * Sets up an intersection observer to notify when elements with the class
                 * `.sticky_sentinel--bottom` become visible/invisible at the bottom of the
                 * container.
                 * @param {!Element} container
                 */
                function observeFooters(container) {
                    const observer = new IntersectionObserver((records, observer) => {
                        for (const record of records) {
                            const targetInfo = record.boundingClientRect;
                            const stickyTarget = record.target.parentElement.querySelector('.sticky');
                            const rootBoundsInfo = record.rootBounds;
                            const ratio = record.intersectionRatio;

                            // Started sticking.
                            if (targetInfo.bottom > rootBoundsInfo.top && ratio === 1) {
                                fireEvent(true, stickyTarget);
                            }

                            // Stopped sticking.
                            if (targetInfo.top < rootBoundsInfo.top &&
                                targetInfo.bottom < rootBoundsInfo.bottom) {
                                fireEvent(false, stickyTarget);
                            }
                        }
                    }, {threshold: [1], root: container});

                    // Add the bottom sentinels to each section and attach an observer.
                    const sentinels = addSentinels(container, 'sticky_sentinel--bottom');
                    sentinels.forEach(el => observer.observe(el));
                }

                /**
                 * @param {!Element} container
                 * @param {string} className
                 */
                function addSentinels(container, className) {
                    return Array.from(container.querySelectorAll('.sticky')).map(el => {
                        const sentinel = document.createElement('div');
                        sentinel.classList.add('sticky_sentinel', className);
                        return el.parentElement.appendChild(sentinel);
                    });
                }

                /**
                 * Dispatches the `sticky-event` custom event on the target element.
                 * @param {boolean} stuck True if `target` is sticky.
                 * @param {!Element} target Element to fire the event on.
                 */
                function fireEvent(stuck, target) {
                    const e = new CustomEvent('sticky-change', {detail: {stuck, target}});
                    document.dispatchEvent(e);
                }
            }
        },
        mounted () {
            this.activeLetter = this.getFirstLetter();
            this.stickyObservers();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";
    @import "../../styles/functions";
    @import "../../styles/mixins";

    .layout {
        padding-right: 40px;
        padding-top: 78px;
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            padding-top: 60px;
            padding-right: 30px;
        }
    }
    .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
        border-color: transparent;
    }
    .v-list {
        padding: 0;
        width: 100%;
        .sticky {
            position: sticky;
            top: 78px; /* adjust sentinel height/positioning based on this position. */
            height: 39px;
            @media screen and (max-width: map-get($css-breakpoints, "md")) {
                height: 30px;
                top: 60px;
            }
        }
        .list-group {
            position: relative;
        }
        .v-subheader {
            font-size: 20px;
            z-index: 99;
            background: white;
            background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 50%, rgba(0,0,0,0.10) 92%);
            color: $primary;
            @extend %font-black;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(226, 223, 223, 0.15);
            }
        }
    }
    .location-item {
        height: 75px;
        background: #FFFFFF;
        padding: 0;
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            height: 60px;
        }
        > div {
            position: relative;
            height: 75px;
            padding: 20px 36px 20px 16px;
            width: 100%;
            background: linear-gradient(0deg, rgba(255,255,255,0.00) 70%, rgba(0,0,0,0.08) 100%);
            @media screen and (max-width: map-get($css-breakpoints, "md")) {
                height: 60px;
                padding: 10px 36px 10px 16px;
            }
        }
        .content {
            .address {
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    font-size: 12px;
                }
            }
        }
        .icons {
            .favourite,
            .information {
                pointer-events: all;
                cursor: pointer;
            }
        }
    }
    .search {
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        right: 40px;
        height: 78px;
        padding: 6px 33px;
        z-index: 999;
        display: flex;
        align-items: center;
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            height: 60px;
            right: 30px;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            padding: 6px 15px;
        }
        .icon {
            position: absolute;
            left: 23px;
            top: 50%;
            height: 26px;
            transform: translateY(-50%);
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                width: 16px;
                height: 20px;
                left: 15px;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .v-input {
            padding-left: 30px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                padding-left: 15px;
            }
        }
    }
    #alphabet-list {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: white;
        width: 40px;
        height: calc(100vh - 90px);
        overflow-y: auto;
        appearance: none;
        box-shadow: 0 2px 17px 0 rgba(0,0,0,0.09);
        &::-webkit-scrollbar {
            display: none;
        }
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            height: calc(100vh - 128px);
            width: 30px;
        }
        .v-list-item {
            text-transform: uppercase;
            min-height: 30px;
            height: 30px;
            padding: 0 12px;
            text-align: center;
            justify-content: center;
            font-size: 12px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                min-height: 25px;
                height: 25px;
            }
            &.active {
                font-weight: 900;
                &:before {
                    display: none;
                }
            }
            &.disabled {
                pointer-events: none;
                color: #ddd !important;
            }
        }
    }
</style>