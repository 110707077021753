const mutations = {
    setLocations (state, locations) {
        state.locations = locations;
    },
    setSelectedLocation (state, location) {
        state.selectedLocation = location;
    },
    setLocationDetailView (state, val) {
        state.showLocationDetail = val;
    },
    setDeviceLocation (state, location) {
        state.deviceLocation = location;
    }
};

export default mutations;