import api from '../../../api/api';

const actions = {
    getLocations ({commit}, cb = null) {
        api.getLocations((locations) => {
            commit('setLocations', locations);
            if (typeof cb === "function") {
                cb();
            }
        });
    },
    setLocations ({commit}, locations) {
        commit('setLocations', locations);
    },
    showLocationDetails({commit}, location) {
        commit('setSelectedLocation', location);
        commit('setLocationDetailView', true);
    },
    closeLocationDetails({commit}) {
        commit('setLocationDetailView', false);
        commit('setSelectedLocation', {});
    },
    setDeviceLocation({commit}, location) {
        commit('setDeviceLocation', location);
    }
};

export default actions;