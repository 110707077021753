<template>
    <div class="d-flex flex-column">
        <v-toolbar id="fav-toolbar">
            <div class="d-flex flex-column">
                <p class="location-title lrg-title color-primary mb-0">Favourites</p>
                <p class="copy mb-0">{{ sortedLocations.length }} Venues Saved</p>
            </div>
        </v-toolbar>
        <v-list>
            <v-list-item v-for="(item, i) in sortedLocations" :key="i" @click="onDetails(item)" class="location-item">
                <div class="d-flex justify-start justify-md-space-between">
                    <div class="icons d-flex d-md-none">
                        <div class="favourite" @click.stop="onFavourite(item)">
                            <img src="/dist/images/icons/heart_outline_favourite.svg" alt="Favourite" />
                        </div>
                    </div>
                    <div class="content pl-4 pl-md-0 d-flex flex-column">
                        <p class="location-title color-black text-uppercase mb-0">{{ item.title }}</p>
                        <p class="address mb-0">{{ item.suburb }} NSW {{ item.postcode }}</p>
                    </div>
                    <div class="icons d-flex">
                        <div class="favourite d-none d-md-block mr-3" @click.stop="onFavourite(item)">
                            <img src="/dist/images/icons/heart_outline_favourite.svg" alt="Favourite" />
                        </div>
                        <div class="information d-none d-md-block" @click="onDetails(item)">
                            <img src="/dist/images/icons/information.svg" alt="Information" />
                        </div>
                        <div class="arrow-right d-flex d-md-none justify-center align-center">
                            <img src="/dist/images/icons/arrow-point-to-right.svg" alt="Next" />
                        </div>
                    </div>
                </div>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        data () {
            return {

            }
        },
        computed: {
            ...mapState({
                favourites: state => state.favourites.favourites
            }),
            sortedLocations() {
                function compare(a, b) {
                    if (a.title < b.title)
                        return -1;
                    if (a.title > b.title)
                        return 1;
                    return 0;
                }

                return this.favourites.sort(compare);
            }
        },
        methods: {
            onFavourite (location) {
                // Select favourite for this item
                this.$store.dispatch('favouriteLocationSelect', location);
                this.$emit('remove', location);
            },
            onDetails (location) {
                // Open location detail view
                this.$store.dispatch('showLocationDetails', location);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";
    @import "../../styles/functions";
    @import "../../styles/mixins";

    #fav-toolbar {
        height: 85px !important;
        display: flex;
        align-items: center;
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            height: 77px !important;
        }
        .copy {
            font-size: 14px;
        }
    }
    .v-list {
        padding: 0;
        width: 100%;
    }
    .location-item {
        height: 75px;
        background: #FFFFFF;
        padding: 0;
        > div {
            position: relative;
            height: 75px;
            padding: 20px 16px;
            width: 100%;
            background: linear-gradient(0deg, rgba(255,255,255,0.00) 50%, rgba(0,0,0,0.10) 100%);
        }
        .content {
            .address {
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    font-size: 12px;
                }
            }
            @media screen and (max-width: map-get($css-breakpoints, "md")) {
                flex-grow: 1;
            }
        }
        .icons {
            .favourite,
            .information {
                min-width: 37px;
                max-width: 37px;
                pointer-events: all;
                cursor: pointer;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .arrow-right {
            margin-right: 15px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                margin-right: 10px;
            }
        }
    }
</style>