<template>
    <div class="app-container">
        <navbar />
        <main>
            <router-view/>
        </main>
        <tabbar />
    </div>
</template>

<style lang="scss">
    @import "styles/globals";

</style>

<script>
    export default {
        data () {
            return {

            }
        },
        methods: {
            trackLocation() {
                if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        let location = {
                            longitude: position.coords.longitude,
                            latitude: position.coords.latitude
                        };
                        this.$store.dispatch('setDeviceLocation', location);
                    });

                    navigator.geolocation.watchPosition(position => {
                        let location = {
                            longitude: position.coords.longitude,
                            latitude: position.coords.latitude
                        };
                        this.$store.dispatch('setDeviceLocation', location);
                    });
                } else {
                    console.log("Geo Location not supported by browser");
                }
            }
        },
        watch: {
            $route (to, from) {
                this.$store.dispatch('closeLocationDetails');
            }
        },
        mounted() {
            this.trackLocation();
            this.$store.dispatch('getLocations');
        }
    }
</script>