<template>
    <v-layout>
        <v-navigation-drawer
                id="venue-search"
                :value="true"
                hide-overlay
                permanent
                absolute
                width="40vw"
                style="background: #FEFEFE"
                disable-resize-watcher
                mobile-break-point="960"
                stateless
        >
            <location-search></location-search>
        </v-navigation-drawer>
        <v-navigation-drawer
                id="detail-view"
                :value="showLocationDetail"
                hide-overlay
                absolute
                width="40vw"
                style="background: #FEFEFE"
                disable-resize-watcher
                mobile-break-point="960"
                stateless
        >
            <location-detail @close="reset"></location-detail>
        </v-navigation-drawer>
        <div id="map"></div>
    </v-layout>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import gmapsInit from '../../utils/gmaps';
    import MarkerClusterer from "@/utils/clusterer";
    import mapStyles from '@/utils/map_styles';

    export default {
        data () {
            return {
                map: null,
                polygons: [],
                loclist: true,
                visibleLocations: [],
                positionMarker: null
            }
        },
        methods: {
            initMap() {
                let center = {lat: -33.0311, lng: 151.6603};
                if (this.deviceLocation.hasOwnProperty('latitude') && this.deviceLocation.hasOwnProperty('longitude')) {
                    center = {
                        lat: this.deviceLocation.latitude,
                        lng: this.deviceLocation.longitude
                    }
                }

                this.map = new google.maps.Map(document.getElementById('map'), {
                    center: center,
                    zoom: 12,
                    disableDefaultUI: true,
                    styles: mapStyles
                });

                let search = this;
                let markers = this.activeLocations.map((location) => {
                    if (location.hasOwnProperty('latitude') && location.latitude !== null && location.hasOwnProperty('longitude') && location.longitude !== null) {
                        let marker = new google.maps.Marker({
                            position: new google.maps.LatLng(location.latitude, location.longitude),
                            icon: '/dist/images/icons/map_marker.png',
                            map: search.map
                        });

                        marker.addListener('click', function() {
                            search.$store.dispatch('showLocationDetails', location);
                        });

                        return marker;
                    }
                });

                let clusterer = new MarkerClusterer(this.map, markers,
                    {
                        imagePath: '/dist/images/icons/clusters/m',
                        styles: {
                            width: 15,
                            textColor: 'transparent',
                            textSize: 0
                        },
                        gridSize: 40
                    });

                // Listeners
                google.maps.event.addListener(this.map, 'bounds_changed', () => {
                    this.onMapMove(this.map.getBounds());

                    if (!this.selectedLocation.hasOwnProperty('id')) {
                        if (this.map.getZoom() > 12) {
                            this.showPolygons();
                        } else {
                            this.hidePolygons();
                        }
                    }
                });

                this.updatePosition();
            },
            showPolygons () {
                if (this.polygons.length === 0) {
                    this.polygons = this.activeLocations.map((location) => {
                        return this.drawPolygon(location);
                    });
                }
            },
            drawPolygon (location) {
                let paths = JSON.parse(location.bus_radius);
                if (paths) {
                    // Because we have to use a Polyline to allow dots,
                    // we need to re-add the first coord to the end of the path to create the final link in the loop
                    paths.push(paths[0]);

                    // Create the symbol for the dotted line
                    let lineSymbol = {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillOpacity: 1,
                        scale: 1.5,
                        fillColor: '#192B41'
                    };

                    return new google.maps.Polyline({
                        strokeColor: '#192B41',
                        strokeOpacity: 0,
                        icons: [{
                            icon: lineSymbol,
                            offset: '0',
                            repeat: '10px'
                        }],
                        path: paths,
                        map: this.map
                    });
                }
            },
            hidePolygons () {
                this.polygons.forEach((polygon) => {
                    polygon.setMap(null);
                });

                this.polygons = [];
            },
            onMapMove (bounds) {
                let locations = [];
                this.activeLocations.forEach((location) => {
                    if (bounds.contains(new google.maps.LatLng(location.latitude, location.longitude))) {
                        locations.push(location);
                    }
                });

                this.visibleLocations = locations;
            },
            updatePosition () {
                if (this.positionMarker !== null) {
                    this.positionMarker.setMap(null);
                }

                if (this.map) {
                    let self = this;
                    this.positionMarker = new google.maps.Marker({
                        position: new google.maps.LatLng(self.deviceLocation.latitude, self.deviceLocation.longitude),
                        map: self.map,
                        icon: '/dist/images/icons/location.png',
                    });
                }
            },
            reset () {
                this.map.setZoom(12);
            }
        },
        computed: {
            ...mapState({
                showLocationDetail: state => state.locations.showLocationDetail,
                deviceLocation: state => state.locations.deviceLocation,
                selectedLocation: state => state.locations.selectedLocation
            }),
            ...mapGetters([
                'activeLocations'
            ]),
            sortedLocations() {
                function compare(a, b) {
                    if (a.title < b.title)
                        return -1;
                    if (a.title > b.title)
                        return 1;
                    return 0;
                }

                return this.visibleLocations.sort(compare);
            }
        },
        watch: {
            deviceLocation(newValue, oldValue) {
                if (newValue.hasOwnProperty('latitude') && newValue.hasOwnProperty('longitude')) {
                    this.updatePosition();
                }
            },
            selectedLocation(newValue, oldValue) {
                if (newValue !== '' && newValue.hasOwnProperty('id')) {
                    setTimeout(() => {
                        this.hidePolygons();
                        this.polygons = [this.drawPolygon(newValue)];
                    }, 100);
                }
            }
        },
        created: function () {
            this.$store.watch(state => state.locations.selectedLocation, () => {
                const selectedLocation = this.$store.state.locations.selectedLocation;
                if (selectedLocation !== '' && selectedLocation.hasOwnProperty('id') && window.innerWidth > 960) {
                    this.map.setZoom(14);
                    this.map.setCenter(new google.maps.LatLng(selectedLocation.latitude, selectedLocation.longitude));
                }
            });
        },
        async mounted () {
            try {
                const google = await gmapsInit();
                this.initMap();
            } catch (error) {
                console.error(error);
            }

            this.$store.dispatch('getLocations');
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";
    @import "../../styles/functions";
    @import "../../styles/mixins";

    #venue-search,
    #detail-view {
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            width: 100vw !important;
        }
    }
    #map {
        width: 60vw;
        margin-left: auto;
        height: calc(100vh - 90px);
        transition: width 0.3s;
    }
</style>